.navListContainer {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.navListContainer h4 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.navListContainer .navList {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}