.detailContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem 0;
}

.detailWrapper {
    width: 82%;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
}

.detailWrapper .description {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
}

.detailWrapper .description h1 {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 150%;
}

.detailWrapper .description a {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 150%;
    color: #5D188F;
}

.detailWrapper .image {
    width: 100%;
}

.detailWrapper .image img {
    width: 100%;
    object-fit: contain;
}

.pointsWrapper {
    width: 68.2%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 7.8%;
    margin-top: 2.5rem;
}

.pointsWrapper img {
    width: 46%;
    margin-bottom: 7.8%;
}

@media (min-width: 868px) {
    .detailContainer {
        margin: 5rem 0;
    }

    .detailWrapper {
        width: 88.23vw;
        display: flex;
        flex-direction: row;
        column-gap: 4.4%;
        /* column-gap: 2.5rem; */
    }

    .detailWrapper .description {
        width: 51.55%;
    }

    .detailWrapper .description h1 {
        font-size: 0.875rem;
    }

    .detailWrapper .description a {
        font-size: 0.875rem;
        color: #5D188F;
    }

    .detailWrapper .image {
        width: 44%;
    }

    .pointsWrapper {
        width: 72.2%;
        column-gap: 5.7%;
        margin-top: 5rem;
    }

    .pointsWrapper img {
        width: 15.3%;
        margin-bottom: 0;
    }
}

@media (min-width: 1200px) {
    .detailContainer {
        margin: 5rem 0;
    }

    .detailWrapper {
        width: 77vw;
        display: flex;
        flex-direction: row;
        column-gap: 6.25rem;
    }

    .detailWrapper .description {
        width: 52%;
    }

    .detailWrapper .description h1 {
        font-size: 1rem;
    }

    .detailWrapper .description a {
        font-size: 1rem;
        color: #5D188F;
    }

    .detailWrapper .image {
        width: 40%;
    }

    .pointsWrapper {
        width: 72.2%;
        column-gap: 5.7%;
        margin-top: 5rem;
    }

    .pointsWrapper img {
        width: 15.3%;
        margin-bottom: 0;
    }
}