.spinner {
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  margin: 0 auto;
  flex: none !important;
}

.double_bounce1,
.double_bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #390760;
  /* background-color: #333; */
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double_bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}