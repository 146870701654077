.navListContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    max-width: 25.688rem;
}

.navListContainer h4 {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}

.navListContainer .navList {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
}

.navListContainer .navList .navListItem {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
}

.navListContainer .navList .navListItem h5 {
    color: #fff;
    font-size: 12px;
    font-weight: 300;
}

@media (min-width: 868px) {
    .navListContainer {
        max-width: 15.688rem;
    }
}