.navbarItem {
  display: inline-block;
  white-space: nowrap;
  padding: 12px 106px 12px 6px;
  border-bottom: 1px solid #662699;

}

.navbarItem a {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.navbarItem a:hover {
  /* color: #20183d; */
  text-decoration: none;
}

.navbarItem a.active {
  /* text-decoration: underline;
  text-underline-offset: 0.5rem; */
  font-weight: 800;
  /* color: #5D188F; */
  color: #fff;
}

@media (min-width: 1020px) {
  .navbarItem {
    padding: 0;
    border: none;
  }

  .navbarItem a {
    color: #0d0a19;
  }

  .navbarItem a.active {
    /* text-decoration: underline;
  text-underline-offset: 0.5rem; */
    font-weight: 800;
    color: #5D188F;
  }
}

@media(min-width: 1200px) {
  .navbarItem a {
    font-size: 16px;
  }
}