.navbarLogo {
  width: 3.125rem;
  height: 2.5rem;
  margin-left: 1rem;
}

@media (min-width: 1200px) {
  .navbarLogo {
    width: 15.88vw;
    /* width: 15.25rem; */
    height: 2.5rem;
    margin-left: 0;
  }
}