.detailContainer {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noProduct {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    margin-top: 6vh;
    color: #162026;
}

.detailWrapper {
    width: 82%;
    margin: 2.5rem 0;
    display: flex;
    flex-direction: column;
    column-gap: 3.75rem;
    row-gap: 2.5rem;
}

.detailWrapper .image {
    width: 100%;
}

.detailWrapper .image img {
    width: 100%;
    object-fit: contain;
}

.detailWrapper .desc {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    text-align: left;
}

.detailWrapper .desc h1 {
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
}

.detailWrapper .desc .detail {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 150%;
}

@media (min-width: 868px) {
    .detailWrapper {
        width: 88.23vw;
        margin: 3.75rem 0;
        display: flex;
        flex-direction: row;
    }

    .detailWrapper .image {
        width: 44.6%;
        height: 44.6%;
    }

    .detailWrapper .desc {
        width: 51.44%;
    }

    .detailWrapper .desc h1 {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: normal;
    }

    .detailWrapper .desc .detail {
        font-size: 0.875rem;
    }

}

@media (min-width: 1200px) {
    .detailWrapper {
        width: 73vw;
        margin: 3.75rem 0;
        display: flex;
        flex-direction: row;
    }

    .detailWrapper .image {
        width: 48.2%;
        height: 48.2%;
    }

    .detailWrapper .desc {
        width: 50%;
    }

    .detailWrapper .desc h1 {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: normal;
    }

    .detailWrapper .desc .detail {
        font-size: 1rem;
    }

}