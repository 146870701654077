.navListContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    max-width: 25.688rem;
}

.navListContainer .footerLogo {
    width: 62px;
    /* width: 5.75rem; */
    height: 42px;
    /* height: 3.875rem; */
}

.navListContainer h4 {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}

.navListContainer .navList {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
}

.navListContainer .mediaLinks {
    display: flex;
    flex-direction: row;
    column-gap: 0.375rem;
}

.navListContainer .mediaLinks img {
    width: 17px;
    height: 17px;
    margin-bottom: 5px;
}

@media (min-width: 868px) {
    .navListContainer {
        max-width: 27.8%;
    }
}

@media (min-width: 1230px) {
    .navListContainer {
        max-width: 15.688rem;
    }
}