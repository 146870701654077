.aboutContainer {
    width: 100%;
    /* margin-top: 1.875rem;
    margin-bottom: 4.173rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutContainer .whoweare {
    width: 82%;
    margin-top: 1.875rem;
    margin-bottom: 4.173rem;
    display: flex;
    flex-direction: column-reverse;
}

/* .aboutContainer .whoweare .image {
    display: none;
} */

.aboutContainer .whoweare .details {
    width: 100%;
}

.aboutContainer .whoweare .details h4 {
    font-size: 0.75rem;
    font-weight: 600;
    color: #541587;
}

.aboutContainer .whoweare .details h1 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #403F3F;
    margin-top: 0.838rem;
}

.aboutContainer .whoweare .details h2 {
    font-size: 0.75rem;
    font-weight: 400;
    color: #605F5F;
    margin-top: 1.75rem;
}

.aboutContainer .thumbnails {
    width: 68%;
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 3.75rem;
    row-gap: 2.5rem;
    margin-bottom: 2.5rem;
}

.aboutContainer .thumbnails .division {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 3.75rem;
}

.aboutContainer .thumbnails .division .card {
    width: 37%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.25rem;
}

.aboutContainer .thumbnails .division .card img {
    width: 100%;
}

.aboutContainer .thumbnails .division .card h4 {
    color: #000;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.aboutContainer .wallPic {
    width: 100%;
}

.aboutContainer .wallPic img {
    width: 100%;
}

.aboutContainer .insights {
    width: 82%;
    display: flex;
    flex-direction: column;
    row-gap: 3.049rem;
    margin-top: 2.563rem;
}

.aboutContainer .insights .details {
    width: 100%;
}

.aboutContainer .insights .details h4 {
    font-size: 0.75rem;
    font-weight: 600;
    color: #541587;
}

.aboutContainer .insights .details h1 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #403F3F;
    margin-top: 0.838rem;
}

.aboutContainer .insights .details .points {
    display: flex;
    flex-direction: column;
    row-gap: 0.625rem;
    margin-top: 1.75rem;
}

.aboutContainer .insights .details .points .pointLine {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
}

.aboutContainer .insights .details .points .pointLine .bullet {
    height: 10px;
    margin-top: 3.5px;
}

.aboutContainer .insights .details .points .pointLine h2 {
    font-size: 0.75rem;
    font-weight: 400;
    color: #605F5F;
    line-height: 150%;
}

.aboutContainer .insights .image {
    width: 100%;
    display: flex;
    justify-content: center;
}

.aboutContainer .insights .image img {
    width: 90%;
}

@media (min-width: 868px) {
    .aboutContainer .whoweare {
        width: 87.84vw;
        display: flex;
        flex-direction: row;
        gap: 4.5%;
    }

    .aboutContainer .whoweare .image {
        width: 40.41%;
        display: flex;
        justify-content: center;
    }

    .aboutContainer .whoweare .image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .aboutContainer .whoweare .details {
        width: 55%;
    }

    .aboutContainer .whoweare .details h4 {
        font-size: 0.75rem;
        margin-top: 1.5rem;
    }

    .aboutContainer .whoweare .details h1 {
        width: 86.2%;
        font-size: 1.375rem;
        margin-top: 0.313rem;
        line-height: normal;
    }

    .aboutContainer .whoweare .details h2 {
        width: 100%;
        font-size: 0.875rem;
        margin-top: 1.25rem;
        line-height: 150%;
    }

    .aboutContainer .thumbnails {
        width: 66.6vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        column-gap: 3.75rem;
        row-gap: 2.5rem;
        margin-bottom: 5rem;
    }

    .aboutContainer .thumbnails .division {
        width: 43.75%;
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 3.75rem; */
    }

    .aboutContainer .thumbnails .division .card {
        width: 42.8%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 2rem;
    }

    .aboutContainer .thumbnails .division .card img {
        width: 100%;
        /* width: 66.666%; */
    }

    .aboutContainer .thumbnails .division .card h4 {
        font-size: 0.875rem;
    }

    .aboutContainer .insights {
        width: 87.84vw;
        display: flex;
        flex-direction: row;
        column-gap: 3%;
    }

    .aboutContainer .insights .details {
        width: 61%;
    }

    .aboutContainer .insights .details h4 {
        font-size: 0.75rem;
        margin-top: 3.328rem;
    }

    .aboutContainer .insights .details h1 {
        width: 85%;
        font-size: 1.375rem;
        margin-top: 0.75rem;
        line-height: normal;
    }

    .aboutContainer .insights .details .points {
        margin-top: 2.5rem;
    }

    .aboutContainer .insights .details .points .pointLine {
        column-gap: 0.875rem;
    }

    .aboutContainer .insights .details .points .pointLine .bullet {
        margin-top: 7px;
    }

    .aboutContainer .insights .details .points .pointLine h2 {
        width: 90%;
        font-size: 0.875rem;
        line-height: 150%;
    }

    .aboutContainer .insights .image {
        width: 36%;
    }

    .aboutContainer .insights .image img {
        width: 100%;
        object-fit: contain;
    }
}

@media (min-width: 1020px) {
    .aboutContainer .thumbnails .division .card img {
        width: 78%;
        /* width: 66.666%; */
    }
}

@media (min-width: 1200px) {
    .aboutContainer .whoweare {
        width: 82vw;
        display: flex;
        flex-direction: row;
        gap: 4%;
    }

    .aboutContainer .whoweare .image {
        width: 43%;
        display: flex;
        justify-content: center;
    }

    .aboutContainer .whoweare .image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .aboutContainer .whoweare .details {
        width: 57%;
    }

    .aboutContainer .whoweare .details h4 {
        font-size: 1rem;
        margin-top: 3.328rem;
    }

    .aboutContainer .whoweare .details h1 {
        width: 85%;
        font-size: 2rem;
        margin-top: 0.75rem;
        line-height: normal;
    }

    .aboutContainer .whoweare .details h2 {
        width: 100%;
        font-size: 1rem;
        margin-top: 2.5rem;
        line-height: 150%;
    }

    .aboutContainer .thumbnails {
        width: 66.6vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        column-gap: 3.75rem;
        row-gap: 2.5rem;
        margin-bottom: 5rem;
    }

    .aboutContainer .thumbnails .division {
        width: 43.75%;
        /* display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 3.75rem; */
    }

    .aboutContainer .thumbnails .division .card {
        width: 42.8%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 2rem;
    }

    .aboutContainer .thumbnails .division .card img {
        width: 66.666%;
    }

    .aboutContainer .thumbnails .division .card h4 {
        font-size: 1rem;
    }

    .aboutContainer .insights {
        width: 82vw;
        display: flex;
        flex-direction: row;
        column-gap: 4%;
    }

    .aboutContainer .insights .details {
        width: 60%;
    }

    .aboutContainer .insights .details h4 {
        font-size: 1rem;
        margin-top: 3.328rem;
    }

    .aboutContainer .insights .details h1 {
        width: 85%;
        font-size: 2rem;
        margin-top: 0.75rem;
        line-height: normal;
    }

    .aboutContainer .insights .details .points {
        margin-top: 2.5rem;
    }

    .aboutContainer .insights .details .points .pointLine {
        column-gap: 0.875rem;
    }

    .aboutContainer .insights .details .points .pointLine .bullet {
        margin-top: 7px;
    }

    .aboutContainer .insights .details .points .pointLine h2 {
        width: 90%;
        font-size: 1rem;
        line-height: 150%;
    }

    .aboutContainer .insights .image {
        width: 36%;
    }

    .aboutContainer .insights .image img {
        width: 100%;
    }
}