.detailContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem 0;
}

.detailWrapper {
    width: 82%;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
}

.detailWrapper .description {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
}

.detailWrapper .description h1 {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 150%;
}

.detailWrapper .image {
    width: 100%;
}

.detailWrapper .image img {
    width: 100%;
    object-fit: contain;
}

@media (min-width: 868px) {
    .detailContainer {
        margin: 5rem 0;
    }

    .detailWrapper {
        width: 88.23vw;
        display: flex;
        flex-direction: row;
        column-gap: 4.44%;
        /* column-gap: 2.5rem; */
    }

    .detailWrapper .description {
        width: 56.77%;
    }

    .detailWrapper .description h1 {
        font-size: 0.875rem;
    }

    .detailWrapper .image {
        width: 38.77%;
    }
}

@media (min-width: 1200px) {
    .detailContainer {
        margin: 5rem 0;
    }

    .detailWrapper {
        width: 77vw;
        display: flex;
        flex-direction: row;
        column-gap: 9%;
        /* column-gap: 4.25rem; */
    }

    .detailWrapper .description {
        width: 53.5%;
    }

    .detailWrapper .description h1 {
        font-size: 1rem;
    }

    .detailWrapper .image {
        width: 40%;
    }
}