.mainSection {
  display: flex;
  flex-direction: column;
  /* margin: auto; */
  width: 100vw;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}

.topSection {
  display: none;
}

.mainNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 90%;
  height: 3.75rem;
}

.navbarList {
  display: none;
}

.navbarList .buttons {
  display: flex;
  flex-direction: row;
  column-gap: 0.75rem;
  /* align-items: center; */
}

.navbarList button {
  white-space: nowrap;
  font-size: 12px;
}

.humburgerButton {
  display: inline-block;
}

@media (min-width: 1020px) {
  .mainSection {
    box-shadow: none;
  }

  .navbarList {
    display: flex;
    align-items: center;
    column-gap: 2rem;
  }

  .humburgerButton {
    display: none;
  }

  .mainNavbar {
    height: 5rem;
  }

  .topSection {
    background: #390760;
    /* height: 37px; */
    height: 2.375rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    column-gap: 0.375rem;
    padding: 0 2%;
  }

  .topSection a {
    height: 16px !important;
  }

  .topSection img {
    width: 16px;
    height: 16px;
    margin-bottom: 5px;
  }
}

@media (min-width: 1200px) {
  .navbarList button {
    font-size: 14px;
  }

}