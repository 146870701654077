.bannerContainer {
    /* width: 100%; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    box-sizing: border-box !important;
    margin-top: 3.75rem;
    /* margin-bottom: 10px; */
}

.bannerContainer .banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    /* justify-content: center; */
    align-items: center;

}

.bannerContainer .bannerImage {
    width: 100%;
    /* position: relative; */
    object-fit: cover;
}

.bannerContainer .desc {
    position: absolute;
    width: 76.3%;
    top: 10.3%;
    text-align: center;
    color: #fff;
}

.bannerContainer .desc img {
    margin-bottom: 1.75rem;
    height: 1.625rem;
}

.bannerContainer .desc h1 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.75rem;
}

.bannerContainer .desc h2 {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 150%;
}

@media (max-width: 353px) {

    .bannerContainer .banner {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

    }

    .bannerContainer .desc {
        top: auto;
        width: 79%;
    }

    .bannerContainer .desc img {
        margin-bottom: 1.013rem;
        height: 1.625rem;
    }

    .bannerContainer .desc h1 {
        font-size: 1.125rem;
        margin-bottom: 1.035rem;
    }

    .bannerContainer .desc h2 {
        font-size: 0.6875rem;
    }
}

@media (min-width: 540px) {

    .bannerContainer .banner {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

    }

    .bannerContainer .desc {
        top: auto;
        width: 77%;
    }

    .bannerContainer .desc img {
        margin-bottom: 1.813rem;
        height: 1.625rem;
    }

    .bannerContainer .desc h1 {
        font-size: 1.375rem;
        margin-bottom: 1.1135rem;
    }

    .bannerContainer .desc h2 {
        font-size: 0.875rem;
    }
}

@media (min-width: 740px) {

    .bannerContainer .banner {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

    }

    .bannerContainer .desc {
        top: auto;
        width: 77%;
    }

    .bannerContainer .desc img {
        margin-bottom: 1.813rem;
    }

    .bannerContainer .desc h1 {
        font-size: 2rem;
        margin-bottom: 1.1135rem;
    }

    .bannerContainer .desc h2 {
        font-size: 1rem;
    }
}

@media (min-width: 869px) {
    .bannerContainer {
        /* width: 97%; */
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* grid-template-rows: 353px !important; */
        height: auto;
    }

    .bannerContainer .desc {
        top: 9.3%;
        width: 82%;
    }

    .bannerContainer .desc img {
        margin-bottom: 1.013rem;
        height: 1.625rem;
    }

    .bannerContainer .desc h1 {
        font-size: 1.375rem;
        margin-bottom: 0.875rem;
    }

    .bannerContainer .desc h2 {
        font-size: 0.75rem;
    }
}

@media (min-width: 991px) {
    .bannerContainer {
        /* width: 97%; */
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* grid-template-rows: 353px !important; */
        height: auto;
    }

    .bannerContainer .desc {
        top: 11.3%;
        width: 77%;
    }

    .bannerContainer .desc img {
        margin-bottom: 1.813rem;
        height: 1.625rem;
    }

    .bannerContainer .desc h1 {
        font-size: 1.375rem;
        margin-bottom: 1.113rem;
    }

    .bannerContainer .desc h2 {
        font-size: 0.75rem;
    }
}

@media (min-width: 1200px) {
    .bannerContainer {
        /* width: 97%; */
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* grid-template-rows: 353px !important; */
        height: auto;
    }

    .bannerContainer .desc {
        top: 10.3%;
        width: 79%;
    }

    .bannerContainer .desc img {
        margin-bottom: 1.413rem;
        height: 2.375rem;
    }

    .bannerContainer .desc h1 {
        font-size: 1.75rem;
        margin-bottom: 0.875rem;
    }

    .bannerContainer .desc h2 {
        font-size: 0.875rem;
    }
}

@media (min-width: 1400px) {
    .bannerContainer {
        /* width: 97%; */
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* grid-template-rows: 353px !important; */
        height: auto;
    }

    .bannerContainer .desc {
        top: 11.3%;
        width: 77%;
    }

    .bannerContainer .desc img {
        margin-bottom: 1.813rem;
    }

    .bannerContainer .desc h1 {
        font-size: 2rem;
        margin-bottom: 1.113rem;
    }

    .bannerContainer .desc h2 {
        font-size: 1rem;
    }
}