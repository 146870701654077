.container {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 1.75rem;
  height: 0.185rem;
  background-color: #390760;
  margin: 0.31rem 0;
  transition: 0.4s;
  border-radius: 3rem;
}

.change .bar1 {
  transform: translate(0, 0.688rem) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -0.688rem) rotate(45deg);
}