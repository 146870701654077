.contactUsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem 0;
    margin: 2.5rem 0 3.75rem 0;
}

.contactUsWrapper {
    width: 81.9%;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
}

.contactUsWrapper .contactUsForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* row-gap: 2.5rem; */
    border-radius: 8px;
    background: #42C2E5;
    padding: 1.813rem;
}

.contactUsWrapper .contactUsForm h1 {
    font-size: 1rem;
    color: #FFF;
    margin: 0 auto 1.563rem auto;
    font-weight: 600;
}

.contactUsWrapper .contactUsForm .input {
    margin-bottom: 1.063rem;
}

.contactUsWrapper .contactUsForm .submit {
    border: none;
    border-radius: 4.417px;
    background-color: #FFF;
    font-size: 0.75rem;
    font-weight: 600;
    width: 36.6%;
    height: 1.953rem;
    padding: 0.414rem 1.381rem;
    margin: 0.5rem auto 0 auto;
}

.contactUsWrapper .contactUsForm .fileInput {
    border: none;
    color: #FFF;
}

.contactUsWrapper .contactUsDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
}

.contactUsWrapper .contactUsDetails .contactUsAddress {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.938rem;
    padding: 1.75rem;
    border-radius: 8px;
    box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.10), 1px 1px 2px 0px rgba(0, 0, 0, 0.10);
}

.contactUsWrapper .contactUsDetails .contactUsAddress h1 {
    font-size: 1rem;
    margin-bottom: 0.813rem;
    font-weight: 600;
    line-height: normal;
}

.contactUsWrapper .contactUsDetails .contactUsAddress .contactInfo {
    display: flex;
    flex-direction: row;
    column-gap: 0.75rem;
}

.contactUsWrapper .contactUsDetails .contactUsAddress .contactInfo img {
    width: 0.688rem;
    margin-bottom: auto;
    margin-top: 4px;
}

.contactUsWrapper .contactUsDetails .contactUsAddress .contactInfo h2 {
    font-size: 0.875rem;
    color: #605F5F;
    font-weight: 400;
    line-height: normal;
}

.contactUsWrapper .contactUsDetails .contactUsMap {
    width: 100%;
    height: 13.8rem;
    border-radius: 8px;
    box-shadow: -0.552px -0.552px 1.104px 0px rgba(0, 0, 0, 0.1), 0.552px 0.552px 1.104px 0px rgba(0, 0, 0, 0.10);
}

@media (min-width: 868px) {
    .contactUsContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3.75rem 0;
    }

    .contactUsWrapper {
        width: 79.2vw;
        display: flex;
        flex-direction: row;
        column-gap: 1.75rem;
    }

    .contactUsWrapper .contactUsForm {
        width: 48.7%;
        display: flex;
        flex-direction: column;
        /* row-gap: 2.5rem; */
        border-radius: 16px;
        padding: 2.5rem 1.875rem;
    }

    .contactUsWrapper .contactUsForm h1 {
        font-size: 1rem;
        margin: 0 auto 1.813rem auto;
    }

    .contactUsWrapper .contactUsForm .input {
        margin-bottom: 1.75rem;
    }

    .contactUsWrapper .contactUsForm .submit {
        border: none;
        border-radius: 8px;
        background-color: #FFF;
        font-size: 0.875rem;
        font-weight: 600;
        width: 32%;
        height: 3rem;
        padding: 0.75rem 2.5rem;
        margin: 0.75rem auto 0 auto;
    }

    .contactUsWrapper .contactUsDetails {
        width: 48.7%;
        display: flex;
        flex-direction: column;
        row-gap: 1.75rem;
    }

    .contactUsWrapper .contactUsDetails .contactUsAddress {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.75rem;
        padding: 2.5rem 3.75rem;
        border-radius: 16px;
    }

    .contactUsWrapper .contactUsDetails .contactUsAddress h1 {
        font-size: 1.25rem;
        margin-bottom: 1.5rem;
    }

    .contactUsWrapper .contactUsDetails .contactUsAddress .contactInfo img {
        margin-top: 6px;
    }

    .contactUsWrapper .contactUsDetails .contactUsAddress .contactInfo h2 {
        font-size: 0.875rem;
    }

    .contactUsWrapper .contactUsDetails .contactUsMap {
        height: 100%;
        /* height: 18rem; */
        border-radius: 16px;
        box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.10), 1px 1px 2px 0px rgba(0, 0, 0, 0.10);
    }
}

@media (min-width: 1020px) {
    .contactUsWrapper .contactUsForm {
        padding: 2.5rem 3.75rem;
    }
}

@media (min-width: 1200px) {
    .contactUsContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3.75rem 0;
    }

    .contactUsWrapper {
        width: 79.2vw;
        display: flex;
        flex-direction: row;
        column-gap: 1.75rem;
    }

    .contactUsWrapper .contactUsForm {
        width: 48.7%;
        display: flex;
        flex-direction: column;
        /* row-gap: 2.5rem; */
        border-radius: 16px;
        padding: 2.5rem 3.75rem;
    }

    .contactUsWrapper .contactUsForm h1 {
        font-size: 1.25rem;
        margin: 0 auto 1.813rem auto;
    }

    .contactUsWrapper .contactUsForm .input {
        margin-bottom: 1.75rem;
    }

    .contactUsWrapper .contactUsForm .submit {
        border: none;
        border-radius: 8px;
        background-color: #FFF;
        font-size: 1rem;
        font-weight: 600;
        width: 32%;
        height: 3rem;
        padding: 0.75rem 2.5rem;
        margin: 0.75rem auto 0 auto;
    }

    .contactUsWrapper .contactUsDetails {
        width: 48.7%;
        display: flex;
        flex-direction: column;
        row-gap: 1.75rem;
    }

    .contactUsWrapper .contactUsDetails .contactUsAddress {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.75rem;
        padding: 2.5rem 3.75rem;
        border-radius: 16px;
    }

    .contactUsWrapper .contactUsDetails .contactUsAddress h1 {
        font-size: 1.25rem;
        margin-bottom: 1.5rem;
    }

    .contactUsWrapper .contactUsDetails .contactUsAddress .contactInfo img {
        margin-top: 6px;
    }

    .contactUsWrapper .contactUsDetails .contactUsAddress .contactInfo h2 {
        font-size: 1rem;
    }

    .contactUsWrapper .contactUsDetails .contactUsMap {
        height: 25rem;
        border-radius: 16px;
        box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.10), 1px 1px 2px 0px rgba(0, 0, 0, 0.10);
    }
}