.navbar {
  position: fixed;
  z-index: 20;
  background-color: #fafafa;
  width: 100vw;
  /* height: 5rem; */
  top: 0;
  display: flex;
}

.navbar.dashboard {
  background-color: #162026;
}

.mainContent {
  margin-top: 3.75rem;
  width: 100%;
  /* padding-bottom: 5rem; */
}

.noScroll {
  overflow: hidden !important;
}

.footer {
  width: 100%;
  margin-top: auto;
}

/* @media (min-width: 868px) {
  .mainContent {
    margin-top: 7.4rem;
  }
} */

@media (min-width: 1020px) {
  .mainContent {
    margin-top: 7.4rem;
  }
}

@media (min-width: 1200px) {
  .mainContent {
    margin-top: 7.4rem;
  }
}