.listContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding: 0 2.125rem; */
    margin-bottom: 3.438rem;
}

.listWrapper {
    width: 82%;
    margin-top: 3.75rem;
}

.listWrapper .table {
    font-family: 'Poppins';
}

.truncateLines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.imageWrapper {
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
}

.imageWrapper .image {
    width: 100%;
    object-fit: contain;

}

.name {
    color: #000;
    cursor: pointer;
}

.name:hover {
    color: #390760;
    font-weight: 600;
}

@media (min-width: 868px) {
    .listWrapper {
        width: 79vw;
        margin-top: 2.5rem;
    }

    .imageWrapper {
        width: 6.25rem;
        height: 6.25rem;
    }
}