.bannerContainer {
    width: 100%;

}

.bannerContainer .banner {
    position: relative;
    height: fit-content;
}

.bannerContainer .banner img {
    position: relative;
    width: 100%;
}

.bannerContainer .banner .textWrapper {
    display: flex;
    flex-direction: column;
    gap: 2.825rem;
    position: absolute;
    top: 15.6%;
    left: 9%;
    color: #FFF;
}

.bannerContainer .banner .textWrapper h1 {
    font-size: 1.5rem;
    width: 73%;
    font-weight: 700;
    line-height: normal;
}

.bannerContainer .banner .textWrapper h2 {
    font-size: 0.875rem;
    font-weight: 400;
}

@media (min-width: 868px) {
    .bannerContainer .banner .textWrapper {
        position: absolute;
        gap: 1.85rem;
        top: 16.9%;
        left: 5.8%;
        margin: auto 0;
    }

    .bannerContainer .banner .textWrapper h1 {
        font-size: 1.75rem;
        width: 63%;
    }

    .bannerContainer .banner .textWrapper h2 {
        font-size: 1rem;
    }
}

@media (min-width: 969px) {
    .bannerContainer .banner .textWrapper {
        position: absolute;
        gap: 1.85rem;
        top: 16.9%;
        left: 5.8%;
        margin: auto 0;
    }

    .bannerContainer .banner .textWrapper h1 {
        font-size: 1.75rem;
        width: 56%;
    }

    .bannerContainer .banner .textWrapper h2 {
        font-size: 1rem;
    }
}

@media (min-width: 1200px) {
    .bannerContainer .banner .textWrapper {
        position: absolute;
        gap: 3rem;
        top: 20%;
        left: 10.5%;
        margin: auto 0;
    }

    .bannerContainer .banner .textWrapper h1 {
        font-size: 2.25rem;
        width: 56%;
    }

    .bannerContainer .banner .textWrapper h2 {
        font-size: 1.25rem;
    }
}