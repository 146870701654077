.bannerContainer {
    /* width: 100%; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    box-sizing: border-box !important;
    margin-top: 3.75rem;
    /* margin-bottom: 10px; */
}

.bannerContainer .banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    /* justify-content: center; */
    align-items: center;

}

.bannerContainer .bannerImage {
    width: 100%;
    /* position: relative; */
    object-fit: cover;
}

.bannerContainer .desc {
    position: absolute;
    width: 60%;
    top: 18.6%;
    text-align: center;
    color: #fff;
}

.bannerContainer .desc h1 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.875rem;
}

.bannerContainer .desc h2 {
    font-size: 0.75rem;
    font-weight: 400;
}

@media (max-width: 363px) {
    .bannerContainer .banner {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

    }

    .bannerContainer .desc {
        top: auto;
        width: 67%;
    }

    .bannerContainer .desc h1 {
        font-size: 1.125rem;
    }

    .bannerContainer .desc h2 {
        font-size: 0.6875rem;
    }
}

@media (min-width: 500px) {
    .bannerContainer .banner {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

    }

    .bannerContainer .desc {
        top: auto;
    }

    .bannerContainer .desc h1 {
        font-size: 1.375rem;
    }

    .bannerContainer .desc h2 {
        font-size: 0.875rem;
    }
}

@media (min-width: 740px) {
    .bannerContainer .banner {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

    }

    .bannerContainer .desc {
        top: auto;
    }

    .bannerContainer .desc h1 {
        font-size: 1.5rem;
    }

    .bannerContainer .desc h2 {
        font-size: 1rem;
    }
}

@media (min-width: 869px) {
    .bannerContainer {
        /* width: 97%; */
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* grid-template-rows: 353px !important; */
        height: auto;
    }

    .bannerContainer .desc {
        top: 11.5%;
        width: 69%;
    }

    .bannerContainer .desc h1 {
        font-size: 1.375rem;
        margin-bottom: 0.675rem;
    }

    .bannerContainer .desc h2 {
        font-size: 0.75rem;
    }
}

@media (min-width: 1028px) {

    .bannerContainer .desc {
        top: 19%;
    }

    .bannerContainer .desc h1 {
        margin-bottom: 1.875rem;
    }

}

@media (min-width: 1200px) {

    .bannerContainer .desc {
        top: 13%;
        width: 64%;
    }

    .bannerContainer .desc h1 {
        font-size: 2rem;
        margin-bottom: 1.475rem;
    }

    .bannerContainer .desc h2 {
        font-size: 1rem;
    }

}

@media (min-width: 1400px) {
    .bannerContainer {
        /* width: 97%; */
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* grid-template-rows: 353px !important; */
        height: auto;
    }

    .bannerContainer .desc {
        top: 19%;
        width: 60%;
        margin-bottom: 1.875rem;
    }

    .bannerContainer .desc h1 {
        font-size: 2rem;
    }

    .bannerContainer .desc h2 {
        font-size: 1rem;
    }
}