html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Poppins', sans-serif;
  color: #0d0a19;
  list-style: none;
  background-color: #fafafa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  font-size: 16px;
}

@media (min-width: 320px) {
  :root {
    font-size: 16px;
  }
}

/* @media (min-width: 640px) {
  :root {
    font-size: 12px;
  }
} */

@media (min-width: 868px) {
  :root {
    font-size: 16px;
  }
}

/* @media (min-width: 1024px) {
  :root {
    font-size: 20px;
  }
} */

/* @media (min-width: 1440px) {
  :root {
    font-size: 16px;
  }
} */

@media (min-width: 1920px) {
  :root {
    font-size: 20px;
  }
}

@media (min-width: 2560px) {
  :root {
    font-size: 30px;
  }
}

/* react-image-gallery - making thumbnail image responsive */
.image-fit img {
  object-fit: scale-down !important;
}

.ant-carousel .slick-dots li button::after {
  display: none !important;
  /* background: none !important; */
  /* opacity: 0 !important; */
}

.ant-carousel .slick-dots-bottom {
  bottom: 30px !important;
}

.ant-carousel .slick-dots li button {
  background: none !important;
}

.ant-carousel .slick-dots li button {
  background: none !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
  color: #fff !important;
  /* border: 1px solid #FFF !important; */
  width: 12px !important;
  height: 12px !important;
  border-radius: 12px !important;
  line-height: 15px !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: #390760 !important;
  border: 1px solid #FFF !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  border-bottom: 1px solid #000 !important;
}

.ant-table-wrapper .ant-table-thead>tr>th::before {
  display: none !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: 0 !important;
}

/* to change color of the mandatory sign of input in left side */
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  /* display: none !important; */
  color: #fff !important;
}

.ant-form-item .ant-form-item-explain-error {
  color: #fc0305;
  font-weight: 500;
}

/* ant input in contact us page */
.ant-input {
  height: 2.25rem;
}

.ant-form-item .ant-form-item-label>label {
  color: #FFF;
  font-weight: 500;
}

@media (min-width: 868px) {
  .ant-input {
    height: 2.375rem;
    font-size: 0.875rem;
  }

  .ant-form-item .ant-form-item-label>label {
    font-size: 0.875rem;
  }
}

@media (min-width: 1200px) {
  .ant-input {
    height: 3rem;
    font-size: 1rem;
  }

  .ant-form-item .ant-form-item-label>label {
    font-size: 1rem;
  }
}