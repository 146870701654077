.navbarList {
  margin: 0;
  margin-top: 76px;
  margin-bottom: 145px;
  padding: 0;
  display: flex;
  gap: 2.222vw;
  /* gap: 2rem; */
}

.navbarButtons {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  /* padding-bottom: 37px; */
  background-color: #541587;
  right: 0;
  width: 60vw;
  padding: 0 2rem 37px 2rem;
}

.navButton {
  height: 45px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

@media(min-width: 1020px) {
  .navbarList {
    margin-top: 0;
    margin-bottom: 0px;
  }

  .navbarButtons {
    display: none;
  }
}